import * as productCodes from '@/constants/product-codes'

import rugs from './rugs'
import rightBedSetup from './rightBedSetup'
import toppers from './toppers'
import pillows from './pillows'
import sabp from './sabp'

/*
This config should be imported into the component for use.
Any named files in this directory are intended as reusable units exclusively for this file.
*/

/*
Content has been designed to take standard HTML tags and format them consistently.
The currently styled and supported tags for the content elements are:
- h3, h4
- p
- ul, li
- em, strong, small (reduces text size, like for "* disclaimers")
*/

const config = {

    [productCodes.SAATVA_CLASSIC]: rightBedSetup,
    [productCodes.SAATVA_LATEX_HYBRID]: rightBedSetup,
    [productCodes.LOOM_AND_LEAF]: rightBedSetup,
    [productCodes.SAATVA_CONTOUR5]: rightBedSetup,
    [productCodes.SOLAIRE]: rightBedSetup,
    [productCodes.MEMORY_FOAM_HYBRID]: rightBedSetup,
    [productCodes.SAATVA_RX]: rightBedSetup,
    [productCodes.ZENHAVEN]: rightBedSetup,
    [productCodes.SAATVA_HD]: rightBedSetup,

    [productCodes.KANAN_BEDROOM_RUG]: rugs,
    [productCodes.TERNA_BEDROOM_RUG]: rugs,
    [productCodes.ADAMAS_BEDROOM_RUG]: rugs,
    [productCodes.CIRRUS_BEDROOM_RUG]: rugs,
    [productCodes.MYRA_BEDROOM_RUG]: rugs,
    [productCodes.NAVI_BEDROOM_RUG]: rugs,

    [productCodes.ADJUSTABLE_BASE_PLUS]: sabp,

    [productCodes.LATEX_MATTRESS_TOPPER]: toppers[productCodes.LATEX_MATTRESS_TOPPER],
    [productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER]: toppers[productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER],
    [productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER]: toppers[productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER],
    [productCodes.FEATHERBED_MATTRESS_TOPPER]: toppers[productCodes.FEATHERBED_MATTRESS_TOPPER],
    [productCodes.MICROCOIL_MATTRESS_TOPPER]: toppers[productCodes.MICROCOIL_MATTRESS_TOPPER],
    [productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER]: toppers[productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER],

    [productCodes.THE_SAATVA_PILLOW]: pillows[productCodes.THE_SAATVA_PILLOW],
    [productCodes.MEMORY_FOAM_PILLOW]: pillows[productCodes.MEMORY_FOAM_PILLOW],
    [productCodes.DOWN_ALTERNATIVE_PILLOW]: pillows[productCodes.DOWN_ALTERNATIVE_PILLOW],
    [productCodes.CLOUD_MEMORY_FOAM_PILLOW]: pillows[productCodes.CLOUD_MEMORY_FOAM_PILLOW],
    [productCodes.ORGANIC_QUILTED_PILLOW]: pillows[productCodes.ORGANIC_QUILTED_PILLOW]
}

export default config
