import { Button } from '@saatva-bits/pattern-library.components.button'

export const buildButton = (label, isActivePDP, href, scrollTo) => {
    return isActivePDP ? (
        <Button kind="primary" onClick={scrollTo}>
            {label}
        </Button>
    ) : (
        <Button kind="primary" href={href}>
            {label}
        </Button>
    )
}

export const buildPrioritizedContent = (productCode, productPriorityList, contentBuilderMap) => {
    const content = []
    productPriorityList.forEach((priorityProductCode) => {
        if (productCode === priorityProductCode) {
            // Current product always goes first
            content.unshift(contentBuilderMap[priorityProductCode](true))
        } else {
            // Others follow priority order
            content.push(contentBuilderMap[priorityProductCode](false))
        }
    })
    return content
}
