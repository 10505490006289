import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import config from './config'
import PictureImgix from '../PictureImgix'
import useDeviceType from '@/hooks/useDeviceType'
import { AccordionSet } from '@saatva-bits/pattern-library.components.accordion-set'
import { Accordion } from '@saatva-bits/pattern-library.components.accordion'
import { scrollToElement, OFFSETS } from '@saatva-bits/pattern-library.utils.position'
import { Button } from '@saatva-bits/pattern-library.components.button'
import { Badge } from '@saatva-bits/pattern-library.components.badge'

import styles from './AdditionalFeaturesPDP.module.scss'

const AdditionalFeaturesPDP = ({
    className,
    productCode
}) => {
    const [activePanel, setActivePanel] = useState(0)
    const featuresContent = config[`${productCode}`] || null
    const { isDesktop } = useDeviceType('desktop')

    if (!featuresContent) {
        return null
    }

    const widths = {
        mobile: 375,
        tablet: 678,
        desktop: 537
    }

    const scrollTo = () => {
        scrollToElement('productPanel', OFFSETS.both, 50)
    }

    const renderPicture = (image, altText) => {
        return (
            <div>
                <PictureImgix
                    folder={image.folder}
                    name={image.file}
                    lazyLoad={false}
                    className={styles.image}
                    widths={widths}
                    alt={altText}
                />
                {image.badge && (
                    <Badge text={image.badge} />
                )}
            </div>
        )
    }

    const renderContent = (content, textTab) => {
        return (
            <div className={styles.content}>
                { featuresContent.displayTabAsHeader &&
                    <h3>{ textTab }</h3>
                }
                {content(scrollTo)}
            </div>
        )
    }

    const wrapperClasses = classNames(
        'section',
        styles.sectionWrapper,
        className,
        { [styles.lightMode]: featuresContent.isLightMode }
    )
    return (
        <section id='tabbedFeatures' className={wrapperClasses}>
            <div className="container u-paddingVertical--base u-paddingBottom--2dot5xl">
                <h2>{featuresContent.title}</h2>
                { featuresContent.description &&
                    <p className='u-paddingTop u-textCenter'>{featuresContent.description}</p>
                }
            </div>
            {isDesktop
                ? <React.Fragment>
                    <div className='container'>
                        <div className={styles.tabbedFeatures__nav}>
                            { featuresContent.content.map((tabContent, i) => {
                                const navItemClasses = classNames(
                                    styles.tabbedFeatures__navItem,
                                    { [styles['tabbedFeatures__navItem--active']]: i === activePanel }
                                )
                                return <Button className={navItemClasses} onClick={() => setActivePanel(i)} key={tabContent.tab}>
                                    {tabContent.tab}
                                </Button>
                            })}
                        </div>
                        <div>
                            { featuresContent.content.map((tabContent, i) => {
                                const tabContentClasses = classNames(
                                    styles.tabbedFeatures__contentWrapper,
                                    {
                                        [styles['tabbedFeatures__contentWrapper--active']]: i === activePanel,
                                        [styles['tabbedFeatures__contentWrapper--leftImage']]: featuresContent.isLeftImage
                                    }
                                )
                                return (
                                    <div key={ tabContent.tab } className={tabContentClasses}>
                                        {renderContent(tabContent.content, tabContent.tab)}
                                        {renderPicture(tabContent.image, tabContent.tab)}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </React.Fragment>
                : <React.Fragment>
                    <AccordionSet
                        className={styles.accordionFeatures}
                        accordionData={ featuresContent.content.map((tabContent) => {
                            return {
                                title: tabContent.tab,
                                content: (
                                    <div key={ tabContent.tab } className={styles.accordionFeatures__contentWrapper}>
                                        { renderPicture(tabContent.image) }
                                        <div className={`${styles.content} t-family--serif`}>
                                            { featuresContent.displayTabAsHeader &&
                                                <h3>{tabContent.tab}</h3>
                                            }
                                            {tabContent.content(scrollTo)}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    >
                        <Accordion
                            className={styles.accordionItem}
                            titleClassName={styles.accordionItem__title}
                            titleContainerClassName={styles.accordionItem__titleContainer}
                            contentContainerClassName={styles.accordionItem__contentContainer}
                        />
                    </AccordionSet>
                </React.Fragment>
            }
        </section>
    )
}

AdditionalFeaturesPDP.propTypes = {
    className: PropTypes.string,
    productCode: PropTypes.string
}

export default AdditionalFeaturesPDP
