import * as productCodes from '@/constants/product-codes'
import { buildButton, buildPrioritizedContent } from './configUtils'

const contentBuilderMap = {
    [productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER]: (isActivePDP) => ({
        tab: 'Graphite memory foam',
        content (scrollTo) {
            return (
                <>
                    <h4>For pressure relief, cooler sleep &amp; a deep, body-hugging feel</h4>
                    <ul>
                        <li>Great for all sleep positions, especially side sleepers</li>
                        <li>Hugs your curves for great pressure relief where you need it most</li>
                        <li>Sleeps cooler with graphite-infused memory foam that draws body heat away</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/graphite-memory-foam-mattress-topper', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/graphite-memory-foam-mattress-topper/layers',
            file: 'graphite-memory-foam-mattress-topper-layers-16-9.jpg'
        }
    }),
    [productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER]: (isActivePDP) => ({
        tab: 'High-density foam',
        content (scrollTo) {
            return (
                <>
                    <h4>For pressure relief & a medium body-contouring feel</h4>
                    <ul>
                        <li>Great for all sleep positions, especially side sleepers</li>
                        <li>Instantly adds pressure relief & medium cushioning to any mattress</li>
                        <li>Most affordable option to prolong the life of an older mattress</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/high-density-foam-mattress-topper', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/high-density-foam-mattress-topper/layers',
            file: 'high-density-foam-mattress-topper-layers-16-9.jpg'
        }
    }),
    [productCodes.FEATHERBED_MATTRESS_TOPPER]: (isActivePDP) => ({
        tab: 'Down alternative',
        content (scrollTo) {
            return (
                <>
                    <h4>For an ultra-plush feel &amp; breathable comfort</h4>
                    <ul>
                        <li>Great for side sleepers</li>
                        <li>Adds an extra layer of plush comfort to alleviate pressure points</li>
                        <li>Great for hot, sweaty sleepers with a breathable, moisture-wicking fill of down alternative and lyocell</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/featherbed-mattress-topper', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/featherbed-mattress-topper/layers',
            file: 'featherbed-mattress-topper-layers-16-9.jpg'
        }
    }),
    [productCodes.LATEX_MATTRESS_TOPPER]: (isActivePDP) => ({
        tab: 'Natural latex',
        content (scrollTo) {
            return (
                <>
                    <h4>For durability, pressure relief & a responsive, buoyant feel</h4>
                    <ul>
                        <li>Great for all sleep positions, especially back sleepers</li>
                        <li>Natural latex offers unmatched pressure relief, breathability, and durability</li>
                        <li>Made with eco-friendly, natural materials</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/latex-mattress-topper', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/latex-mattress-topper/layers',
            file: 'latex-mattress-topper-layers-16-9.jpg'
        }
    }),
    [productCodes.MICROCOIL_MATTRESS_TOPPER]: (isActivePDP) => ({
        tab: 'Micro-coils',
        content (scrollTo) {
            return (
                <>
                    <h4>For a responsive feel, body-contouring support &amp; pressure relief</h4>
                    <ul>
                        <li>Great for all sleep positions</li>
                        <li>Hugs your curves for great pressure relief where you need it most</li>
                        <li>Sleeps cooler with gel-infused comfort foam</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/microcoil-mattress-topper', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/microcoil-mattress-topper/layers',
            file: 'microcoil-mattress-topper-layers-16-9.jpg'
        }
    }),
    [productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER]: (isActivePDP) => ({
        tab: 'Organic quilted',
        content (scrollTo) {
            return (
                <>
                    <h4>For the ultimate in plush, weightless comfort &amp; pressure relief</h4>
                    <ul>
                        <li>Great for all sleep positions, especially side sleepers</li>
                        <li>Plush, quilted wool batting plus natural latex for unmatched pressure relief, breathability & cushioning</li>
                        <li>GOTS® certified organic — our most eco-friendly mattress topper option</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/organic-quilted-mattress-topper', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/organic-quilted-mattress-topper/layers',
            file: 'organic-quilted-mattress-topper-layers-16-9.jpg'
        }
    })
}

const PRODUCT_PRIORITY_LIST = [
    productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER,
    productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER,
    productCodes.FEATHERBED_MATTRESS_TOPPER,
    productCodes.LATEX_MATTRESS_TOPPER,
    productCodes.MICROCOIL_MATTRESS_TOPPER,
    productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER
]

const buildConfig = (productCode) => {
    const content = buildPrioritizedContent(productCode, PRODUCT_PRIORITY_LIST, contentBuilderMap)
    return {
        title: 'We have a mattress topper for every sleep need',
        displayTabAsHeader: true,
        content
    }
}

const toppers = {
    [productCodes.LATEX_MATTRESS_TOPPER]: buildConfig(productCodes.LATEX_MATTRESS_TOPPER),
    [productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER]: buildConfig(productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER),
    [productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER]: buildConfig(productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER),
    [productCodes.FEATHERBED_MATTRESS_TOPPER]: buildConfig(productCodes.FEATHERBED_MATTRESS_TOPPER),
    [productCodes.MICROCOIL_MATTRESS_TOPPER]: buildConfig(productCodes.MICROCOIL_MATTRESS_TOPPER),
    [productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER]: buildConfig(productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER)
}

export default toppers
