const sabp = {
    title: 'Upgrade your comfort with the touch of a button',
    displayTabAsHeader: true,
    content: [
        {
            tab: 'Full body massage',
            content () {
                return (
                    <>
                        <h4>Head Massage:</h4>
                        <p>3 intensities of vibrating massage for the top half of your torso*</p>
                        <h4>Full-body wave massage:</h4>
                        <p>3 intensities of vibrating massage for your entire body*</p>
                        <h4>Foot/leg massage:</h4>
                        <p>3 intensities of vibrating massage for your lower torso*</p>
                        <p><small><em>* Massage automatically shuts off after 15 minutes.</em></small></p>
                    </>
                )
            },
            image: {
                folder: 'products/adjustable-base-plus/couple',
                file: 'adjustable-base-plus-couple-16-9.jpg'
            }
        },
        {
            tab: 'Preset settings',
            content () {
                return (
                    <>
                        <p>Enjoy instant comfort with one touch of a button. Preset settings include:</p>
                        <h4>Head up preset:</h4>
                        <p>Elevates the head of the bed to its maximum height with one touch.</p>
                        <h4>Flat preset:</h4>
                        <p>Lowers the bed back to its flat position with one touch.</p>
                        <h4>Anti-snore preset:</h4> 
                        <p>Elevates the head of the base at the ideal 7 degrees to open up airways and reduce snoring.</p>
                        <h4>Zero-gravity preset:</h4>
                        <p>Feel like you’re floating in weightless comfort with one-touch, perfectly balanced head & foot support.</p>
                        <h4>Memory preset:</h4>
                        <p>Save your favorite positions for sleeping, watching TV, or just relaxing.</p>
                    </>
                )
            },
            image: {
                folder: 'products/adjustable-base-plus/wall-hugging',
                file: 'adjustable-base-plus-wall-hugging-16-9.jpg'
            }
        },
        {
            tab: 'Under-bed lighting',
            content () {
                return (
                    <p>Provides soft LED lighting without disturbing the peace, perfect for middle-of-the-night bathroom or kitchen trips.</p>
                )
            },
            image: {
                folder: 'products/adjustable-base-plus/under-bed-lighting',
                file: 'adjustable-base-plus-under-bed-lighting-16-9.jpg'
            }
        },
        {
            tab: 'More functions',
            content () {
                return (
                    <>
                        <h4>In-remote flashlight:</h4>
                        <p>Your remote doubles as a mini flashlight to help you find your way around in the dark.</p>
                        <h4>Safety lock/unlock:</h4>
                        <p>Prevents accidental button presses.</p>
                        <h4>Head & Foot up & down:</h4>
                        <p>Elevate and lower the head & foot of the bed incrementally.</p>
                    </>
                )
            },
            image: {
                folder: 'products/adjustable-base-plus/remote',
                file: 'adjustable-base-plus-remote-16-9.jpg'
            }
        }
    ]
}

export default sabp
