import * as productCodes from '@/constants/product-codes'
import { buildButton, buildPrioritizedContent } from './configUtils'

const contentBuilderMap = {
    [productCodes.THE_SAATVA_PILLOW]: (isActivePDP) => ({
        tab: 'Saatva Latex Pillow',
        content (scrollTo) {
            return (
                <>
                    <h4>Our best seller - For plush yet responsive support</h4>
                    <ul>
                        <li>Ideal for relieving neck discomfort </li>
                        <li>Natural latex core offers unmatched breathability and durability</li>
                        <li>Available in two loft fill heights to accommodate all sleep positions</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/the-saatva-pillow', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/the-saatva-pillow/side/standard-loft',
            file: 'the-saatva-pillow-side-standard-loft-16-9.jpg',
            badge: 'Most Popular'
        }
    }),
    [productCodes.MEMORY_FOAM_PILLOW]: (isActivePDP) => ({
        tab: 'Graphite Memory Foam',
        content (scrollTo) {
            return (
                <>
                    <h4>For contouring support with cooling comfort</h4>
                    <ul>
                        <li>Ideal for hot sleepers</li>
                        <li>Advanced graphite technology draws excess heat away</li>
                        <li>Breathable shredded memory foam & natural latex core</li>
                        <li>Available in two loft fill heights to accommodate all sleep positions</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/memory-foam-pillow', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/memory-foam-pillow/side',
            file: 'memory-foam-pillow-side-16-9.jpg'
        }
    }),
    [productCodes.DOWN_ALTERNATIVE_PILLOW]: (isActivePDP) => ({
        tab: 'Down Alternative',
        content (scrollTo) {
            return (
                <>
                    <h4>For a lightweight, airy feel that's still supportive</h4>
                    <ul>
                        <li>Ideal for allergy sufferers</li>
                        <li>Plush blend of microdenier down alternative fibers and memory fiber clusters</li>
                        <li>100% hypoallergenic</li>
                        <li>Available in two loft fill heights to accommodate all sleep positions</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/down-alternative-pillow', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/down-alternative-pillow/side',
            file: 'down-alternative-pillow-side-16-9.jpg'
        }
    }),
    [productCodes.CLOUD_MEMORY_FOAM_PILLOW]: (isActivePDP) => ({
        tab: 'Cloud Memory Foam',
        content (scrollTo) {
            return (
                <>
                    <h4>For a lofty feel with shapeable, contouring support</h4>
                    <ul>
                        <li>Ideal for sleepers who want the feel of a memory foam pillow and the responsiveness of latex foam</li>
                        <li>Blend of shredded gel-infused memory foam & natural latex foam</li>
                        <li>Breathable and temperature-neutral to keep even hot sleepers comfortable</li>
                        <li>Buoyant natural latex foam helps keep its loft and shape</li>
                        <li>Available in two loft fill heights to accommodate all sleep positions</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/cloud-memory-foam-pillow', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/cloud-memory-foam-pillow/side',
            file: 'cloud-memory-foam-pillow-side-16-9.jpg'
        }
    }),
    [productCodes.ORGANIC_QUILTED_PILLOW]: (isActivePDP) => ({
        tab: 'Organic Quilted',
        content (scrollTo) {
            return (
                <>
                    <h4>For an ideal combination of durability and cushiony softness</h4>
                    <ul>
                        <li>Ideal for the most eco-conscious sleepers</li>
                        <li>Fully certified organic from cover to core</li>
                        <li>Superbly breathable, moisture-wicking, antimicrobial & 100% hypoallergenic</li>
                        <li>Organic cotton knit cover quilted with organic New Zealand wool for extra plushness</li>
                        <li>Available in two loft fill heights to accommodate all sleep positions</li>
                    </ul>
                    { buildButton('Shop Now', isActivePDP, '/bedding/organic-quilted-pillow', scrollTo) }
                </>
            )
        },
        image: {
            folder: 'products/organic-quilted-pillow/side',
            file: 'organic-quilted-pillow-side-16-9.jpg'
        }
    })
}

const PRODUCT_PRIORITY_LIST = [
    productCodes.THE_SAATVA_PILLOW,
    productCodes.MEMORY_FOAM_PILLOW,
    productCodes.DOWN_ALTERNATIVE_PILLOW,
    productCodes.CLOUD_MEMORY_FOAM_PILLOW,
    productCodes.ORGANIC_QUILTED_PILLOW
]

const buildConfig = (productCode) => {
    const content = buildPrioritizedContent(productCode, PRODUCT_PRIORITY_LIST, contentBuilderMap)
    return {
        title: 'We have a pillow for every sleep need',
        isLeftImage: true,
        content
    }
}

const pillows = {
    [productCodes.THE_SAATVA_PILLOW]: buildConfig(productCodes.THE_SAATVA_PILLOW),
    [productCodes.MEMORY_FOAM_PILLOW]: buildConfig(productCodes.MEMORY_FOAM_PILLOW),
    [productCodes.DOWN_ALTERNATIVE_PILLOW]: buildConfig(productCodes.DOWN_ALTERNATIVE_PILLOW),
    [productCodes.CLOUD_MEMORY_FOAM_PILLOW]: buildConfig(productCodes.CLOUD_MEMORY_FOAM_PILLOW),
    [productCodes.ORGANIC_QUILTED_PILLOW]: buildConfig(productCodes.ORGANIC_QUILTED_PILLOW)
}

export default pillows
